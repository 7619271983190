<template lang="pug">
button.pag-button(:class="[small && '_small', active && '_active', disabled && '_disabled']")
  span(v-if="type === 'number'") {{ value }}
  ui-icon(v-if="type === 'prev'" :icon="UiIconNames.Chevron_ArrowLeft" :size="14")
  ui-icon(v-if="type === 'dots'" :icon="UiIconNames.Icon_Dots" :size="14")
  ui-icon(v-if="type === 'next'" :icon="UiIconNames.Chevron_ArrowRight" :size="14")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "PagButton",
  components: {
    UiIcon,
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<'number'|'prev'|'next'|'dots'>,
      default: 'number',
    },
    value: {
      type: Number,
      default: 1,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
.pag-button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 32px;
  width: fit-content;
  min-width: 32px;

  font-size: 14px;
  line-height: 16px;
  background-color: #f0f2f5;
  border: none;
  border-radius: 2px;

  &._active {
    font-weight: bold;
    color: var(--main-color-white);
    background-color: #409eff;
  }

  &._disabled {
    color: #a8abb2;
  }

  &._small {
    font-size: 12px;
    line-height: 14px;
    height: 24px;
    min-width: 24px;
  }

  &:not(._disabled):not(._active) {
    &:hover {
      cursor: pointer;
      color: #409eff;
    }
  }
}
</style>
